<template>
  <div id="RpackageDetail">
    <div class="vx-row">
      <feather-icon
        v-if="EnglishLan"
        @click="back()"
        icon="ChevronLeftIcon"
        style="width: 30px; cursor: pointer"
      >
      </feather-icon>
      <feather-icon
        v-else
        @click="back()"
        icon="ChevronRightIcon"
        style="width: 30px; cursor: pointer"
      >
      </feather-icon>
      <p class="text-xl">
        {{ $t("Package") }}
      </p>
    </div>

    <div class="m-5 vx-row">
      <div class="vx-col lg:w-3/4">
        <h3 class="darkBlue mt-10">
          {{ reservedMedicalPackage.MedicalPackage.Name }}
        </h3>

        <p
          class="mt-5"
          v-html="reservedMedicalPackage.MedicalPackage.Description"
        ></p>
        <p class="mt-5">
          {{ $t("DurationByDay") }} : {{ reservedMedicalPackage.DurationByDay }}
        </p>

        <h5 class="mt-5" v-if=" reservedMedicalPackage.MedicalPackage.MedicalPackageIncludedItems!=null && reservedMedicalPackage.MedicalPackage.MedicalPackageIncludedItems!=undefined&& reservedMedicalPackage.MedicalPackage.MedicalPackageIncludedItems.length>0">{{ $t("ThisPackageincludes") }}</h5>
        <point
          class="ml-5 mr-5"
          v-for="item in reservedMedicalPackage.MedicalPackage
            .MedicalPackageIncludedItems"
          :data="item"
          :key="item.title"
        />
        <h5 v-if=" reservedMedicalPackage.MedicalPackage.MedicalPackageExcludedItems!=null && reservedMedicalPackage.MedicalPackage.MedicalPackageExcludedItems!=undefined&& reservedMedicalPackage.MedicalPackage.MedicalPackageExcludedItems.length>0" class="mt-5">{{ $t("ThisPackagedoesnotinclude") }}</h5>
        <point
          class="ml-5 mr-5"
          v-for="item in reservedMedicalPackage.MedicalPackage
            .MedicalPackageExcludedItems"
          :data="item"
          :key="item.title"
        />
      </div>

      <div class="vx-col lg:w-1/4 w-full mt-10">
        <div>
          <div>
            <span class="text-2xl" style="color: primary"
              >{{ $t("HowManyPassengers") }} :
            </span>
            <span>{{ reservedMedicalPackage.NumberOfCompanions }}</span>
          </div>
          <div>
            <span class="text-2xl" style="color: primary"
              >{{ $t("NumberOfBeneficiary") }} :
            </span>
            <span>{{ reservedMedicalPackage.NumberOfBeneficiary }}</span>
          </div>
          <div>
            <span class="text-2xl" style="color: primary"
              >{{ $t("Date") }} :</span
            >
            <span v-if="reservedMedicalPackage.ReservedDate">{{
              reservedMedicalPackage.ReservedDate.split("T")[0]
            }}</span>
            <span
              v-if="
                reservedMedicalPackage.PreferrdDateFrom &&
                reservedMedicalPackage.PreferrdDateTo
              "
              >{{
                reservedMedicalPackage.PreferrdDateFrom.split("T")[0] +
                "-" +
                reservedMedicalPackage.PreferrdDateTo.split("T")[0]
              }}</span
            >
          </div>
          <vs-button
            color="rgb(255, 255, 255)"
            class="text-2xl w-full"
            text-color="rgb(0, 156, 255)"
          >
            {{ $t("Price") }}: {{ reservedMedicalPackage.PriceInUSD }} $
          </vs-button>
          <vs-button
            color="rgb(255, 255, 255)"
            class="text-2xl w-full mt-2"
            text-color="rgb(0, 156, 255)"
            v-if="reservedMedicalPackage.Status"
          >
            {{ $t("Status") }}: {{ reservedMedicalPackage.Status.Name }}
          </vs-button>
          <vs-button
            color="rgb(255, 255, 255)"
            class="text-2xl w-full mt-2"
            text-color="rgb(0, 156, 255)"
            v-if="reservedMedicalPackage.PaymentStatus"
          >
            {{ $t("Payment") }}: {{ reservedMedicalPackage.PaymentStatus.Name }}
          </vs-button>


          <!-- <vs-button
            color="rgb(255, 255, 255)"
            class="text-xl w-full mt-2 btn btn-danger"
            text-color="red"
          
            background="rgb(255, 255, 255)"
            @click="CancelReservedMedicalPackage()"
            v-if="reservedMedicalPackage.Status.Name=='Pending'"
          >
            {{ $t("CancelreservedMedicalPackage") }}
          </vs-button> -->

          <button type="button" class="text-xl w-full mt-2 btn btn-danger"   
          @click="CancelReservedMedicalPackage()"
          background="rgb(255, 255, 255)"
          text-color="red"
          style="width: 100%; height: 50px; color: whitesmoke; border-radius: 1px; background-color: red;"
          v-if="reservedMedicalPackage.Status.Name=='Pending'"
          >
          {{ $t("CancelreservedMedicalPackage") }}
          </button>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import point from "../../../components/point.vue";
import moduleReservedPackage from "@/store/reservedPackage/moduleReservedPackage.js";
export default {
  components: {
    point,
  },
  data() {
    return {
      EnglishLan: false,
      ShowConfirmation: false,
      Model: {},
      reservedMedicalPackage: {},
    };

  },
  methods: {
    back() {
      this.$router.go(-1);
    },

    CancelReservedMedicalPackage()
    {
      debugger;
      var reservedMedicalPackageID= this.$route.params.PackageID;
      this.$store.dispatch("ReservatedPackage/CancelReservedMedicalPackage", reservedMedicalPackageID)
      .then((res) => {
          if (res.status == 200) {
            debugger;
            this.$router.push("/patient/home");
          }
        })
        .catch(() => {
          window.showError();
        });
    }
  },
  created() {
    this.EnglishLan =
      localStorage.getItem("SaveLang") == "en" ||
      localStorage.getItem("SaveLang") == null
        ? true
        : false;

    if (!moduleReservedPackage.isRegistered) {
      this.$store.registerModule("ReservatedPackage", moduleReservedPackage);
      moduleReservedPackage.isRegistered = true;
    }
    debugger;
    var id = this.$route.params.PackageID;
    if (id) {
      this.$store
        .dispatch("ReservatedPackage/GetReservedMedicalPackage", id)
        .then((res) => {
          this.reservedMedicalPackage = res.data.Data;
        });
    }
  },
};
</script>
<style >
.customInput {
  border-radius: 10px;
  padding: 5px;
  height: 40px;
}
.mycard .vx-card {
  /* padding: 0px !important; */
  background: unset;
}
.Border {
  border-radius: 10px;
}
</style>